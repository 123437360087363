@import "../../globalColor";

.footer-text {
  text-align: center;
  color: $subTitle !important;
}

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.footer-social-links {
  display: flex;
  justify-content: center;
  gap: 1rem 1rem;
}

.footer-icon {
  max-width: 35px;
}
