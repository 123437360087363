@import "../../globalColor";
@import "@fontsource/oswald";
@import "@fontsource/open-sans";
@import "@fontsource/roboto-mono";
@import "@fontsource/lato";
@import "@fontsource/montserrat";
@import "@fontsource/roboto";
@import "@fontsource/inter";

.intro-main {
    position: relative;
    text-align: center;
  }

  .intro-image-div > img {
    max-width: 100%;
    height: auto;
    align-items: center;
    margin-top: -80px;
  }

  .intro-name-text {
    margin-bottom: 30px;
    font-size: 35px;
    font-family: "Oswald";
    font-weight: bold;
  }

  .intro-text {
    position: absolute;
    bottom: 64px;
    left: 64px;
    font-size: 18px;
    text-align: left;
    font-family: "Open Sans"
  }
  .resume-button {
    position: absolute;
    bottom: 64px;
    left: 64px;
  }


  @media (max-width: 1380) {
    .intro-name-text {
        margin-bottom: 20px;
        font-size: 20px;
        font-family: "Oswald";
        font-weight: bold;
      }
    .intro-text {
        bottom: 32px;
        left: 32px;
        font-size: 15px;
    }
    .intro-image-div > img {
      height: 500px;
      align-items: center;
      margin-top: -80px;
    }
  }

  @media (max-width: 768px) {
    .intro-name-text {
        margin-bottom: 20px;
        font-size: 20px;
        font-family: "Oswald";
        font-weight: bold;
      }
    .intro-text {
        bottom: 32px;
        left: 32px;
        font-size: 15px;
    }
    .intro-image-div > img {
      height: 500px;
      align-items: center;
      margin-top: -80px;
    }
  }


  @media (max-width: 320px) {
    .intro-name-text {
        margin-bottom: 20px;
        font-size: 20px;
        font-family: "Oswald";
        font-weight: bold;
      }
    .intro-text {
      bottom: 32px;
      left: 32px;
      font-size: 15px;
    }
    .intro-image-div > img {
      height: 500px;
      align-items: center;
      margin-top: -80px;
    }
}